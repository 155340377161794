import React, { useContext } from 'react'
import AuthHook from '../hooks/AuthHook'
import RedirectHook from '../hooks/RedirectHook'
import Context from '../utils/context/Context'
import Spinner from '../widgets/Spinner'

const Auth = ({ history }) => {
  const {
    token,
    setAuthState,
    setToken,
    authState: { loggingIn },
  } = useContext(Context)

  if (token) {
    AuthHook({
      token,
      setToken,
      history,
    })
  } else {
    RedirectHook({
      setAuthState,
      loggingIn,
      history,
    })
  }

  return <Spinner message="Loading the application..." />
}

export default Auth

import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel } from '../utils/helpers'
import FormLabel from './FormLabel'

const DATE = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
}

const DOBInput = ({ id, valueRange, dateType, value }) => {
  const { setPersonDateOfBirth } = useActions()
  const options = [...Array(valueRange[1] - valueRange[0] + 1)].map((_, i) =>
    (valueRange[0] + i).toString(),
  )
  if (dateType === DATE.YEAR) options.reverse()

  return (
    <Autocomplete
      disablePortal
      openOnFocus
      id="combo-box-demo"
      options={options}
      noOptionsText="Invalid date"
      sx={{
        width: 100,
        // Removes the icon for the popup indicator, !important is needed
        '& .MuiAutocomplete-popupIndicator': { display: 'none' },
        '& .MuiOutlinedInput-root': { paddingRight: '36px !important' },
      }}
      renderInput={(params) => <TextField {...params} />}
      value={value?.toString() || null}
      onChange={(_, value) => {
        setPersonDateOfBirth({ id, dateType, value })
      }}
    />
  )
}

const DateOfBirth = ({ id }) => {
  const { localization, countryCode, dateOfBirth } = useSelector(
    ({ localization, questionnaire }) => ({
      localization,
      countryCode: questionnaire.countryCode,
      dateOfBirth: questionnaire.persons[id]?.dateOfBirth ?? {},
    }),
  )
  const [isInvalid, setIsInvalid] = useState(false)

  const currentYear = new Date().getFullYear()
  const { day, month, year } = dateOfBirth
  const isGBR = countryCode === 'GBR'
  const { YEAR, MONTH, DAY } = DATE
  const inputProps = {
    day: { placeholder: 'Day', value: day, dateType: DAY, valueRange: [1, 31] },
    month: { placeholder: 'Month', value: month, dateType: MONTH, valueRange: [1, 12] },
    year: {
      placeholder: 'Year',
      value: year,
      dateType: YEAR,
      valueRange: [1850, currentYear],
    },
  }
  const dateOrder = isGBR
    ? [inputProps.day, inputProps.month, inputProps.year]
    : [inputProps.year, inputProps.month, inputProps.day]

  return (
    <FormLabel
      label={
        getLabel(localization, 'dateOfBirth') +
        ' (' +
        (isGBR ? 'Day-Month-Year' : 'Year-Month-Day') +
        ')'
      }
    >
      <Box display="flex" gap={1} maxWidth={350}>
        {dateOrder.map((date) => (
          <DOBInput
            key={'dobInput-' + date.placeholder}
            value={date.value}
            dateType={date.dateType}
            valueRange={date.valueRange}
            id={id}
            setIsInvalid={setIsInvalid}
          />
        ))}
      </Box>
      {isInvalid && (
        <Typography variant="subtitle2" color="error">
          Invalid date value
        </Typography>
      )}
    </FormLabel>
  )
}

export default DateOfBirth

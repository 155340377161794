export const PERSON_CONDITIONS = [
  // phenotype conditions
  {
    label: 'Endometriosis',
    id: 'HP:0030127',
    type: 'phenotype',
  },
  {
    label: 'Chronic pancreatitis that lasted longer than 6 months',
    id: 'HP:0006280',
    type: 'phenotype',
  },
  {
    label: 'Tumours or growths',
    id: 'tumour',
    type: 'phenotype',
  },
  {
    label: 'Tumour or growth in the pituitary, parathyroid or adrenal gland',
    id: 'pituitary',
    type: 'phenotype',
  },
  {
    label: 'More than 50 moles (not freckles)',
    id: 'moleNevi',
    type: 'phenotype',
  },
  {
    label: 'More than 10 polyps removed from the bowel (colon or rectum)',
    id: 'polypsRemoved',
    type: 'phenotype',
  },
  {
    label: 'Had a blood clot',
    id: 'bloodClot',
    type: 'phenotype',
  },
  {
    label: 'Lobular carcinoma in situ (LCIS)',
    id: 'HP:0030076',
    type: 'phenotype',
  },
  // heart symptoms
  {
    id: 'palpitations',
    label: 'Palpitations',
    type: 'heartSymptoms',
  },
  {
    id: 'dizziness',
    label: 'Dizziness',
    type: 'heartSymptoms',
  },
  {
    id: 'fainting',
    label: 'Fainting or loss of consciousness',
    type: 'heartSymptoms',
  },
  {
    id: 'chestPain',
    label: 'Chest pain',
    type: 'heartSymptoms',
  },
  {
    id: 'breathlessness',
    label: 'Breathlessness',
    type: 'heartSymptoms',
  },
  // heart problems
  {
    id: 'heartProblems',
    label: 'Heart problems',
  },
  // heart conditions
  {
    id: 'heartConditions',
    label: 'Heart Conditions',
  },
  {
    id: 'aneurysm',
    label: 'Aneurysm',
    type: 'heartConditions',
  },
  {
    id: 'arrhythmia',
    label: 'Arrhythmia',
    type: 'heartConditions',
  },
  {
    id: 'arvc',
    label: 'Arrhythmogenic right ventricular cardiomyopathy (ARVC)',
    type: 'heartConditions',
  },
  {
    id: 'atrial',
    label: 'Atrial fibrillation',
    type: 'heartConditions',
  },
  {
    id: 'brugada',
    label: 'Brugada syndrome',
    type: 'heartConditions',
  },
  {
    id: 'cardiomyopathy',
    label: 'Cardiomyopathy',
    type: 'heartConditions',
  },
  {
    id: 'amyloidosis',
    label: 'Cardiac amyloidosis',
    type: 'heartConditions',
  },
  {
    id: 'cardiacArrest',
    label: 'Cardiac arrest',
    type: 'heartConditions',
  },
  {
    id: 'cpvt',
    label: 'Catecholaminergic polymorphic ventricular tachycardia (CPVT)',
    type: 'heartConditions',
  },
  {
    id: 'dvt',
    label: 'Deep vein thrombosis (DVT)',
    type: 'heartConditions',
  },
  {
    id: 'dcm',
    label: 'Dilated cardiomyopathy (DCM)',
    type: 'heartConditions',
  },
  {
    id: 'fabry',
    label: 'Fabry disease',
    type: 'heartConditions',
  },
  {
    id: 'fh',
    label: 'Familial hypercholesterolemia (FH)',
    type: 'heartConditions',
  },
  {
    id: 'arteryBlock',
    label: 'Heart attack or blockage of an artery',
    type: 'heartConditions',
  },
  {
    id: 'heartDefect',
    label: 'Heart defect present at birth',
    type: 'heartConditions',
  },
  {
    id: 'heartDisease',
    label: 'Heart disease',
    type: 'heartConditions',
  },
  {
    id: 'heartValve',
    label: 'Heart valve problems',
    type: 'heartConditions',
  },
  {
    id: 'hemophilia',
    label: 'Hemophilia',
    type: 'heartConditions',
  },
  {
    id: 'bloodPressure',
    label: 'High blood pressure',
    type: 'heartConditions',
  },
  {
    id: 'cholesterol',
    label: 'High cholesterol',
    type: 'heartConditions',
  },
  {
    id: 'hcm',
    label: 'Hypertrophic cardiomyopathy (HCM)',
    type: 'heartConditions',
  },
  {
    id: 'loeysDietz',
    label: 'Loeys-Dietz syndrome',
    type: 'heartConditions',
  },
  {
    id: 'long',
    label: 'Long QT syndrome',
    type: 'heartConditions',
  },
  {
    id: 'marfan',
    label: 'Marfan syndrome',
    type: 'heartConditions',
  },
  {
    id: 'muscularDystrophy',
    label: 'Muscular dystrophy (e.g. Duchenne muscular dystrophy, Myotonic dystrophy)',
    type: 'heartConditions',
  },
  {
    id: 'pe',
    label: 'Pulmonary embolism (PE)',
    type: 'heartConditions',
  },
  {
    id: 'sickle',
    label: 'Sickle cell disease',
    type: 'heartConditions',
  },
  {
    id: 'thalassemia',
    label: 'Thalassemia',
    type: 'heartConditions',
  },
  {
    id: 'thrombophilia',
    label: 'Thrombophilia',
    type: 'heartConditions',
  },
  {
    id: 'valvular',
    label: 'Valvular disease',
    type: 'heartConditions',
  },
  {
    id: 'vonWillebrand',
    label: 'Von Willebrand disease',
    type: 'heartConditions',
  },
  // relative heart conditions
  {
    id: 'epilepsy',
    label: 'Epilepsy or Seizures',
  },
  {
    id: 'hearingLoss',
    label: 'Hearing loss',
  },
]

import { useEffect } from 'react'
import { Auth } from '../api/Auth'

const useAuth = ({ token, setToken, history }) => {
  // The login call must not be in App.js file
  // because it should not be called again after login id done
  // unless the idle timer is invoked.
  useEffect(() => {
    ;(async () => {
      await Auth.login({
        token,
        success() {
          return new Promise((resolve) => {
            setToken(() => {
              resolve(true)

              return ''
            })
          })
        },
        async redirect() {
          history.push('/questionnaire')
        },
      })
    })()
  }, [])

  return true
}

export default useAuth

import errors from '../../data/errors.json'
import history from '../../utils/history'

export const navigateToErrorScreen = ({ fetchType = '', status = '', cause = '' }) => {
  let errMessages = []

  switch (true) {
    case !window.navigator.onLine:
      errMessages = errors.NETWORK_DISCONNECT
      break
    case fetchType === 'login' && status === '401':
      errMessages = [cause, ...errors.URL_TYPO]
      break
    case fetchType === 'login' && status === '409':
      errMessages = [cause, ...errors.SUBMITTED]
      break
    case status === '401' || status === '403':
      errMessages = [cause, ...errors.SESSION_ERROR_MESSAGE]
      break
    // Any string message (not number type statusCode) from the network.
    case status !== '*' && isNaN(status):
      errMessages = [status]
      break
    // No message available
    default:
      errMessages = errors.UNEXPECTED
      break
  }

  // In the submission, we should not switch the page
  // because the user should know the current error status at the same page.
  if (fetchType !== 'submission') {
    history.push('/message', { errMessages })
  }

  throw new Error()
}

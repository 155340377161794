import { Alert } from '@mui/material'
import TextField from '@mui/material/TextField'
import React from 'react'
import { useActions } from '../hooks/useActions'
import { getLabel, renderContent } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import FormLabel from '../widgets/FormLabel'
import IntegerDetails from '../widgets/IntegerDetails'
import Chooser from './Chooser'

const { CAUSE_OF_DEATH, AGE_OF_DEATH, POST_MORTEM } = PersonFieldPaths

let time = null

const DeathDetails = ({
  personId,
  causeOfDeath,
  ageOfDeath,
  postMortemPerformed,
  localization,
  config,
}) => {
  const { setPersonLifeStatus } = useActions()

  const handleDeathReason = ({ target: { value } }) => {
    if (time) {
      clearTimeout(time)
    }

    time = setTimeout(() => {
      setPersonLifeStatus({ id: personId, type: 'causeOfDeath', value })
    }, 5)
  }

  return renderContent({
    renderMap: {
      lifeStatusDetails: (
        <FormLabel key="lifeStatusDetails" label={getLabel(localization, CAUSE_OF_DEATH)}>
          <TextField value={causeOfDeath} onChange={handleDeathReason} />
        </FormLabel>
      ),
      lifeStatusAge: (
        <FormLabel key="lifeStatusAge" label={getLabel(localization, AGE_OF_DEATH)}>
          <IntegerDetails
            value={ageOfDeath}
            onChange={(value) => {
              setPersonLifeStatus({ id: personId, type: 'ageOfDeath', value })
            }}
          />
        </FormLabel>
      ),
      postMortemReport: (
        <>
          <Chooser
            key="postMortemReport"
            label={getLabel(localization, POST_MORTEM)}
            selectedValue={postMortemPerformed}
            onChange={(value) => {
              if (value !== postMortemPerformed) {
                setPersonLifeStatus({ id: personId, type: 'postMortemPerformed', value })
              }
            }}
            orderSchema={['Y', 'N', 'U']}
            path="yesNoUnknown"
          />
          <Alert key="postMortemReportInfo" severity="info">
            {getLabel(localization, POST_MORTEM, '', 'subLabel')}
          </Alert>
        </>
      ),
    },
    config,
  })
}

export default DeathDetails

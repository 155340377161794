import { Container } from '@mui/material'
import TextField from '@mui/material/TextField'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import ConditionalSection from '../../widgets/ConditionalSection'
import FormLabel from '../../widgets/FormLabel'

export default function RelatedByBlood({ ancestryProperty, label, subLabel }) {
  const { setPartnershipProps } = useActions()

  const { probandId, relationships } = useSelector(
    ({ questionnaire: { probandId, relationships } }) => ({
      probandId,
      relationships,
    }),
  )

  const { properties = [] } =
    relationships.find(({ properties }) => {
      return properties.some(({ type }) => type === ancestryProperty)
    }) || {}

  const { isPresent = '', value = '' } =
    properties.find(({ type }) => type === ancestryProperty) || {}

  const setRelationship = (_isPresent) => {
    if (isPresent !== _isPresent) {
      const relatedByBlood = {
        type: ancestryProperty,
        isPresent: _isPresent,
      }

      if (isPresent !== 'Y') {
        relatedByBlood.value = ''
      }

      setPartnershipProps({
        id: probandId,
        relationProperties: [relatedByBlood],
      })
    }
  }

  const setRelationshipDesc = (desc) => {
    const relationProperty = {
      type: ancestryProperty,
      isPresent,
      value: desc,
    }

    // It is a workaround solution.
    // It is better delete `value` property here
    // than in `FamilyHelper`.
    if (!desc) {
      delete relationProperty.value
    }

    setPartnershipProps({
      id: probandId,
      relationProperties: [relationProperty],
    })
  }

  return (
    <Container>
      <ConditionalSection
        label={label}
        showWhenCondition="Y"
        conditionState={isPresent}
        onConditionStateChange={(value) => setRelationship(value)}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      >
        {subLabel && (
          <FormLabel label={subLabel}>
            <TextField
              fullWidth
              rows={2}
              multiline={true}
              inputProps={{ maxLength: 20000 }}
              onChange={({ target: { value } }) => setRelationshipDesc(value)}
              value={value}
            />
          </FormLabel>
        )}
      </ConditionalSection>
    </Container>
  )
}

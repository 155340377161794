import { Main } from './Main'
import { navigateToErrorScreen } from './utils/errors'

export class Auth extends Main {
  static baseUrl = this.getRoot()

  static async login({ token = '', success, failure = undefined, redirect = undefined }) {
    const url = `${this.baseUrl}/login/?accessToken=${token}`
    try {
      const { ok = true } = (await fetch(url, { method: 'GET' })) || {}

      if (!ok) {
        const response = await (await fetch(url, { method: 'GET' })).json()
        throw new Error(response.status, { cause: response.message })
      }

      // Control SessionTimeout
      if (!redirect && success) {
        success()
      }
      // Login with token
      if (redirect && (await success())) {
        await redirect()
      }
    } catch (err) {
      if (failure) {
        failure()
      }

      navigateToErrorScreen({
        fetchType: 'login',
        status: err.message,
        cause: err.cause,
      })
    }
  }

  static async logout({ addToast = undefined } = {}) {
    try {
      const { ok = true } = (await fetch('/logout', { method: 'POST' })) || {}

      if (!ok) {
        throw new Error('Unable to logout.')
      }

      if (addToast) {
        addToast('success', 'info', 'Successfully Logged Out')
      }
    } catch (err) {
      if (addToast) {
        addToast('error', 'danger', err.message)
      }

      navigateToErrorScreen({
        fetchType: 'logout',
        status: err.message,
      })
    }
  }

  static async getSession() {
    const url = `${this.baseUrl}/questionnaire/current-session`

    try {
      const currentStatus = await (await fetch(url, { method: 'GET' })).json()
      const { errors, error, status, message } = currentStatus || {}

      if (errors || error) {
        throw new Error(status, { cause: message || error || errors?.[0]?.detail || '*' })
      }

      return currentStatus
    } catch (err) {
      navigateToErrorScreen({
        fetchType: 'getSession',
        status: err.message,
        cause: err.cause,
      })
    }
  }
}

import { Alert, Container, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import AncestrySelect from './AncestrySelect'
import AshkenaziAncestry from './AshkenaziAncestry.jsx'
import RelatedByBlood from './RelatedByBlood'

const Parents = () => {
  const { setParentsRelationships } = useActions()

  const { localization, persons, relationships, probandId, config } = useSelector(
    ({ questionnaire, localization, configuration }) => ({
      localization,
      probandId: questionnaire.probandId,
      persons: questionnaire.persons,
      relationships: questionnaire.relationships,
      config: configuration.sectionConfig.parents.questions,
    }),
  )

  const bothParentMembers = Object.values(persons).filter(
    ({ relationshipToProband }) =>
      relationshipToProband === 'probandsFather' || relationshipToProband === 'probandsMother',
  )

  RemoveLoadingHook()

  useEffect(() => {
    let parentPartnership = {
      id: uuidv4(),
      properties: [],
      type: 'partnership',
    }

    if (bothParentMembers.length < 2) {
      const [parent = undefined] = bothParentMembers
      const fatherId = parent?.sex === 'M' ? parent.id : uuidv4()
      const motherId = parent?.sex === 'F' ? parent.id : uuidv4()

      const parents = {
        [fatherId]: {
          id: fatherId,
          relationshipToProband: 'probandsFather',
          sex: 'M',
        },
        [motherId]: {
          id: motherId,
          relationshipToProband: 'probandsMother',
          sex: 'F',
        },
      }

      if (parent) {
        const id = parent.sex === 'M' ? fatherId : motherId

        delete parents[id]
      }

      const probandParentRelationship = Object.keys(parents).map((id) => {
        return {
          id: uuidv4(),
          properties: [],
          source: id,
          target: probandId,
          type: 'parent',
        }
      })

      parentPartnership = {
        ...parentPartnership,
        source: fatherId,
        target: motherId,
      }

      setParentsRelationships({
        parentsPerson: { ...persons, ...parents },
        parentsRelationship: [...relationships, ...probandParentRelationship, parentPartnership],
      })
    }

    if (bothParentMembers.length === 2) {
      const _bothParentMembers = [...bothParentMembers]

      _bothParentMembers.sort(({ sex: sex1 }, { sex: sex2 }) => sex2.localeCompare(sex1))

      const [father, mother] = _bothParentMembers

      parentPartnership = {
        ...parentPartnership,
        source: father.id,
        target: mother.id,
      }

      const hasPartnership = relationships.some(
        ({ type, source, target }) =>
          type === parentPartnership.type &&
          source === parentPartnership.source &&
          target === parentPartnership.target,
      )

      if (!hasPartnership) {
        setParentsRelationships({
          parentsRelationship: [...relationships, parentPartnership],
        })
      }
    }
  }, [])

  const renderMap = {
    motherAncestry: <AncestrySelect key="ancestry-mother" side="maternal" />,
    fatherAncestry: <AncestrySelect key="ancestry-father" side="paternal" />,
    parentsConsang: (
      <Alert key="info" severity="info">
        Some medical conditions can be passed down in families (inherited). These inherited
        conditions can be more common in people from certain parts of the world. For this reason, it
        is important for us to know what part of the world your family is from. This information may
        be used in our risk assessment, as well as in planning and interpreting genetic test
        results. Ancestry is different from nationality or citizenship, and many people have
        ancestors from more than one area.
      </Alert>
    ),
    parentsRelated: (
      <RelatedByBlood
        key="pc"
        ancestryProperty="consanguineous"
        label={getLabel(localization, 'patient.parents.consanguinity')}
        subLabel={getLabel(localization, 'patient.parents.consanguinity.related')}
      />
    ),
    parentsAshkenazi: (
      <AshkenaziAncestry
        key="pa"
        label={getLabel(localization, 'patient.ancestry.ashkenaziJewish')}
      />
    ),
  }
  const content = []

  renderContent({ renderMap, config, content })

  return (
    <Container>
      <Typography key="instructions" variant="body2">
        Please add one or more known countries or regions of ancestry on both sides of the family:
      </Typography>
      {content}
    </Container>
  )
}

export { Parents }

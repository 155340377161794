import React, { useEffect, useContext } from 'react'
import Context from '../utils/context/Context'
import Spinner from '../widgets/Spinner'

const Loading = ({ history }) => {
  const { setToken } = useContext(Context)

  useEffect(() => {
    if (history.location.search) {
      setToken(history.location.search.split(/\/?accessToken=/)[1])
    }

    history.push('/auth')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner message="Loading the Application..." />
}

export default Loading

import { useEffect } from 'react'

const useRedirect = ({ setAuthState, loggingIn, history }) => {
  useEffect(() => {
    if (loggingIn) {
      setAuthState((prevState) => ({
        ...prevState,
        loggingIn: false,
      }))
    }

    history.push('/questionnaire')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export default useRedirect

import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { renderContent, subQuestions } from '../../utils/helpers'
import ConditionalSection from '../../widgets/ConditionalSection'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

function HeartProblemDetails({ id, heartProblems = {} }) {
  const { setPersonConditionNotes, setPersonConditionTreatments } = useActions()
  const {
    isUnderSpecialistCare = '',
    lastAppointmentDate = '',
    lastAppointmentDetails = '',
  } = heartProblems.treatments?.find(({ type }) => type === 'other') || {}

  const treatmentProps = {
    id,
    conditionId: 'heartProblems',
    treatmentType: 'other',
  }

  return (
    <>
      <TextFieldQuestion
        key="heartProblemsDetails"
        label="Please provide details"
        value={heartProblems.notes || ''}
        handleChange={(value) =>
          setPersonConditionNotes({ id, conditionId: 'heartProblems', value })
        }
      />
      <ConditionalSection
        key="underSpecialistCare"
        label="Are you currently under the care or have previously been
        under the care of a heart specialist?"
        orderSchema={['Y', 'N', 'U']}
        path="yesNoUnknown"
        showWhenCondition="Y"
        conditionState={isUnderSpecialistCare}
        onConditionStateChange={(value) => {
          if (value !== isUnderSpecialistCare) {
            if (value === 'Y') {
              setPersonConditionTreatments({
                ...treatmentProps,
                value: { isUnderSpecialistCare: value },
                isPerformed: value,
              })
            } else {
              setPersonConditionTreatments({
                ...treatmentProps,
                value: {
                  isUnderSpecialistCare: value,
                  lastAppointmentDetails: '',
                  lastAppointmentDate: '',
                },
                isPerformed: value,
              })
            }
          }
        }}
        cleanUpChildQuestions={() => {}}
      >
        <TextFieldQuestion
          key="lastAppointmentDate"
          label="When was your last appointment?"
          value={lastAppointmentDate}
          handleChange={(value) => {
            setPersonConditionTreatments({
              ...treatmentProps,
              value: { lastAppointmentDate: value },
              isPerformed: 'Y',
            })
          }}
        />
        <TextFieldQuestion
          key="lastAppointmentDetails"
          label="Please provide details, including any tests you have
          had and approximately when you had the tests done:"
          value={lastAppointmentDetails}
          handleChange={(value) => {
            setPersonConditionTreatments({
              ...treatmentProps,
              value: { lastAppointmentDetails: value },
              isPerformed: 'Y',
            })
          }}
        />
      </ConditionalSection>
    </>
  )
}

function HeartProblems({ config, id }) {
  const { heartProblems = {} } = useSelector(({ questionnaire }) => ({
    heartProblems: questionnaire.persons[id].conditions?.find(({ id }) => id === 'heartProblems'),
  }))
  const {
    setPersonConditions,
    setPersonConditionIsPresent,
    setPersonConditionNotes,
    removePersonConditionTreatments,
  } = useActions()
  const { isPresent = '', notes = '' } = heartProblems

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      label="Do you have a history of any heart problems?"
      conditionState={isPresent}
      onConditionStateChange={(value) => {
        if (!isPresent) {
          setPersonConditions({ id, conditionId: 'heartProblems' })
        }
        if (value !== isPresent) {
          setPersonConditionIsPresent({ id, conditionId: 'heartProblems', value })
        }
      }}
      cleanUpChildQuestions={() => {
        if (notes) setPersonConditionNotes({ id, conditionId: 'heartProblems', value: '' })
        if (heartProblems.treatments?.length > 0)
          removePersonConditionTreatments({
            id,
            conditionId: 'heartProblems',
            treatmentType: 'other',
          })
      }}
    >
      {renderContent({
        renderMap: {
          heartProblemsDetails: (
            <HeartProblemDetails key="heartProblemsDetails" id={id} heartProblems={heartProblems} />
          ),
        },
        config: subQuestions({ config, key: 'heartProblems' }),
      })}
    </ConditionalSection>
  )
}

export default HeartProblems
